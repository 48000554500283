<template>
   <div v-if="value">
      <v-dialog persistent v-model="showDialog" width="640" >
         <v-card>
            <v-card-title
               color="primary" class="py-1 pr-2"
               style="background-color:#01579B;color:white;"
               primary-title
            >
               Point Control
               <v-spacer/>
               <v-btn icon
                  color="white"
                  @click="closeDialog">
                  <v-icon>mdi-close</v-icon>
               </v-btn>
            </v-card-title>

            <v-card-text class="pt-1 pb-4" style="background-color:#F6F6F6;min-height:100px;">
               <v-progress-linear v-if="showFetching"
                  indeterminate
                  color="primary"
               ></v-progress-linear>
               <v-container v-if="point && !showFetching"
                  fluid style="font-size:16px;text-align:left;">

                  <v-row align="left" v-if="setterPoint.name">
                     <v-col cols="3" style="font-size:16px;"
                        class="py-0 pt-2">
                        Name
                     </v-col>
                     <v-col cols="9" class="ma-0 pa-1 py-0 pt-2"
                        style="font-size:16px;font-weight:600;">
                        {{setterPoint.name}}
                     </v-col>
                  </v-row>

                  <v-row v-if="setterPoint.reportName && 1==2"
                     align="start">
                     <v-col cols="3" style="font-size:16px;"
                        class="py-0 pt-1">
                        ReportName
                     </v-col>
                     <v-col cols="9" class="ma-0 pa-1 py-0 pt-1" 
                        style="font-size:16px;font-weight:600;">
                        {{setterPoint.reportName}}
                     </v-col>
                  </v-row>

                  <v-row  class="mt-2" v-if="hasMode">
                     <v-col cols="3" style="font-size:16px;"
                        class="py-0 pt-2">
                        Mode
                     </v-col>
                     <v-col cols="9" class="ma-0 pa-1 py-0 pt-2" 
                        style="font-size:20px;">
                        <v-select class="ma-0" color="#444444"
                           v-model="currentMode"
                           :items="supportModes"
                           item-text="label"
                           item-value="value"
                           label=""
                           persistent-hint
                           return-object
                           single-line hide-details
                           outlined dense
                           :style="{'background-color': currentMode.bgcolor}"
                        ></v-select>
                     </v-col>
                  </v-row>

                  <!-- is bool point -->
                  <v-row  v-if="isBoolPoint"
                      class="mt-2">
                     <v-col cols="3" style="font-size:16px;line-height:40px;"
                        class="py-0 pt-2">
                        Value
                     </v-col>
                     <v-col cols="9" class="ma-0 pa-1 py-0 pt-2"
                        style="font-size:20px;">
                        <v-btn v-for="(boolVal, bIndex) in boolValues"
                           :key="bIndex"
                           :outlined="boolVal.value!=setterPoint.value"
                           @click="setterPoint.value=boolVal.value"
                           color="primary"
                           class="mr-4 px-8 text-none">
                           <b>{{boolVal.label}}</b>
                        </v-btn>
                        <!-- 
                           :disabled="currentMode.value=='auto' && hasMode" -->
                        <v-select  v-if="1==2"
                           class="ma-0" color="#444444"
                           v-model="setterPoint.value"
                           :items="boolValues"
                           item-text="label"
                           item-value="value"
                           label=""
                           persistent-hint
                           single-line hide-details
                           outlined dense
                        ></v-select>
                     </v-col>
                  </v-row>
                  <!-- not bool point -->
                  <v-row v-else
                     class="mt-2">
                     <v-col cols="3" style="font-size:16px;line-height:40px;"
                        class="py-0 pt-2">
                        Value
                     </v-col>
                     <v-col cols="9" class="ma-0 pa-1 py-0 pt-2" 
                        style="font-size:20px;">

                        <div style="width:100%;height:40px;padding:0px;border: 1.5px solid #CCCCCC;border-radius: 4px;background-color:#FFFFFF;">
                           <!--
                           <input v-if="hasMode && currentMode.value=='auto'"
                              type="text" v-model="setterPoint.value"
                              disabled
                              style="background-color:#F0F0F0;height:39px;width:65%;line-height:38px;margin-top:-1px;text-align:right;border-radius:4px 0px 0px 4px;font-size:18px;font-weight:600;color:#444444;padding:0px 8px;"
                              >
                              -->
                           <input
                              @focus="showKeyboard"
                              type="text" v-model="setterPoint.value"
                              style="height:39px;width:65%;line-height:38px;margin-top:-1px;text-align:right;border-radius:4px 0px 0px 4px;font-size:20px;font-weight:600;color:#444444;padding:0px 8px;background-color:transparent;"
                              >
                           <span
                              style="float:right;width:35%;margin-top:0px;font-weight:700;border-radius:0px 4px 4px 0px;text-align:center;background-color:#EFEBE9;height:37px;line-height:37px;padding: 0px 4px;">
                              {{setterPoint.unit}}
                           </span>
                        </div>
                     </v-col>
                  </v-row>
               </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="px-6 py-2" style="background-color:#F6F6F6;">
               <v-spacer/>
               <v-btn
                  color="#999999" dark
                  class="px-4 text-none"
                  @click="closeDialog"
               >
                  Close
               </v-btn>
               <v-btn
                  color="#01579B" dark
                  class="px-4 text-none"
                  :disabled="!(allowControl && setterPoint.canWrite)"
                  @click="closeDialogWithValue"
               >
                  Apply
               </v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </div>
</template>

<script>
// import {mapGetters } from "vuex";
export default {
   props: ['value', 'point'],
   computed: {
      // ...mapGetters(["allowControl"]),
      hasMode: function () {
         return this.point.sourceMode && this.point.sourceMode.length>0
      },
      hasDisplayOption : function (){
         return this.point && this.point.options && this.point.options.labels && this.point.options.labels.length>0
      },
      isBoolPoint: function () {
         console.log(this.setterPoint)
         //return (this.setterPoint && typeof(this.setterPoint.value) == 'boolean')
         return (this.setterPoint && this.setterPoint.dataType && this.setterPoint.dataType.includes('BOOL'))
      },
      boolValues: function (){
         if (this.point && this.point.options && this.point.options.labels && this.point.options.labels.length>1){
            return [
               {label: this.point.options.labels[0], value: true},
               {label: this.point.options.labels[1], value: false},
            ]
         } else {
            return this.defaultBoolValues
         }
      }
   },
   watch: {
      value: async function (){
         this.showDialog = this.value
         if(!this.value) return
         let payload = {
            devId: "masterDev",
            points: []
         }
         if(this.point.sourceMode) payload.points.push({reportName: this.point.sourceMode})
         if(this.point.reportName) payload.points.push({reportName: this.point.reportName})
         await this.fetchLastValue(payload)
      }
   },
   data () {
      return {
         allowControl: true,
         showDialog: false,
         showFetching: false,
         showApplying: false,
         setterPoint: {},
         currentMode: { label: 'Auto', value: 'auto', bgcolor:'#BBDEFB'},
         supportModes: [
            { label: 'Auto', value: 'auto', bgcolor:'#BBDEFB'},
            { label: 'Manual', value: 'manual', bgcolor:'#EF9A9A'},
         ],
         defaultBoolValues: [
            { label: 'ON', value: true},
            { label: 'OFF', value: false}
         ],
         socketAskKey: new Date().getTime()
      }
   },
   sockets: {
      callHTTPRequestResult: function (payload) {
         if(payload && payload.askKey == this.socketAskKey) {
            if (payload.result && payload.url.includes('getLastValuePoints')) {
               this.handlerGetValueReturn(payload.result.content)
            }
            if (payload.result && payload.url.includes('setPoints')) {
               this.handleApiSetResult(payload.result)
            }
         }
      }
   },
   methods: {
      closeDialog () {
         this.$emit('input', false)
      },
      async closeDialogWithValue () {
         let payload = {points: []}
         // let actionDesc= '' + this.setterPoint.name + ', '
         if(this.hasMode){
            if(this.currentMode){
               if(this.currentMode.value == 'manual') {
                  // actionDesc += 'set MANUAL control ' + this.getPointValueInStr()
                  payload.points.push({
                     reportName: this.point.reportName,
                     value: this.setterPoint.value
                     })
               } else {
                  // actionDesc += 'set AUTO mode '  + this.getPointValueInStr()
                  payload.points.push({
                     reportName: this.point.reportName,
                     value: this.setterPoint.value
                     })
               }
            }
            
            payload.points.push({
               reportName: this.point.sourceMode,
               value: this.currentMode.value == 'manual'
               })
         } else {
            // actionDesc += 'set to ' + this.getPointValueInStr()
            payload.points.push({
                  reportName: this.point.reportName,
                  value: this.setterPoint.value
                  })
         }
         // console.log(actionDesc)
         if(this.$root.isRemote) {
            this.callApiOverOTA(
               "POST",
               this.$root.apiGate + 'setPoints',
               payload)
         } else {
            try {
               let res = await this.axios.post(this.$root.apiGate + 'setPoints', payload)
               this.handleApiSetResult(res.data)
            } catch (err) {
               alert(err)
            }
         }
         
      },
      getPointValueInStr() {
         if(this.isBoolPoint){
            if(this.setterPoint.value){
               return this.boolValues[0].label
            } else {
               return this.boolValues[1].label
            }
         } else {
            return this.setterPoint.value + (this.setterPoint.unit?this.setterPoint.unit:'')
         }
      },
      async fetchLastValue (payload) {
         this.showFetching = true
         if (this.$root.isRemote) {
            this.callApiOverOTA(
               "POST",
               this.$root.apiGate +  'getLastValuePoints',
               payload)
         } else {
            try {
               let res = await this.axios.post(this.$root.apiGate +  'getLastValuePoints', payload)
               if(res.data.status=="OK"){
                  this.handlerGetValueReturn(res.data.content)
               } else {
                  alert(res.data.message)
                  this.$emit('input', false)
               }
            } catch (err) { 
               alert(err) 
               this.$emit('input', false)
            }
         }
      },
      handleApiSetResult (payload) {
         if(payload.status == "OK") {
            this.$emit('input', false)
         } else {
            alert(payload.message)
         }
      },
      handlerGetValueReturn (points) {
         //  console.log(points)
         if(points && points.length>0){
            points.forEach(retPoint => {
               if(retPoint.reportName == this.point.reportName) {
                  this.setterPoint = retPoint
                  if(this.setterPoint){
                     if(this.setterPoint.dataType.includes('BOOL')) {
                        this.setterPoint.value = Boolean(this.setterPoint.value)
                     } else {
                        this.setterPoint.value = Math.round(100*Number(this.setterPoint.value))/100
                     }
                     
                  }
               }
               if(this.point.sourceMode && retPoint.reportName == this.point.sourceMode) {
                  if(retPoint.value == true){
                     this.currentMode = { label: 'Manual', value: 'manual', bgcolor:'#EF9A9A'}
                  } else {
                     this.currentMode = { label: 'Auto', value: 'auto', bgcolor:'#BBDEFB'}
                  }
               }
            })
            
         } else {
            alert("Point not found!")
            this.$emit('input', false)
         }
         this.showFetching = false
      },
      callApiOverOTA (method, url, body) {
         this.$socket.emit('callHTTPRequest', 
         { 
            devCode: this.$root.devCode,
            askKey: this.socketAskKey,
            method: method,
            url: url,
            body: body
         })
      },
      showKeyboard (e) {
         this.$eventBus.$emit("requestShowKeyboard", e.target, 'numeric')
      }
   }
}
</script>

<style>

</style>